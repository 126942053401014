import React from 'react'
import { Row, Col, Collapse, Tabs } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './Sandbox.module.css'
import { AllFilesGraph } from './AllFilesGraph';
import { SuspiciousFilesGraph } from './SuspiciousFilesGraph';
import { FileTypesGraph } from './FileTypesGraph';

export default function GraphsArea() {
  
const collapseGraphsTabs = [
    {
      key: 1,
      label: 'Всего',
      children: (<AllFilesGraph/>)
    },
    {
      key: 2,
      label: 'Подозрительные вердикты',
      children: (<SuspiciousFilesGraph/>)

    },
    {
      key: 3,
      label: 'Типы файлов',
      children: (<FileTypesGraph/>),
    }
  ]
  return(
    <Row justify={"center"} gutter={8}>
        <Col span={24}>
          <Collapse
            size="small"
            expandIcon={({ isActive }) => <DownOutlined style={{ fontSize: 14, paddingTop: 8 }} rotate={isActive ? 180 : 0} />}
            style={{
              backgroundColor: 'white',
              padding: '16px 24px 16px 24px',
              alignContent: 'center',
              borderRadius: '8px',
              fontSize: 18,
              fontWeight: 600,
              minHeight: '60px',

            }}
            ghost
            defaultActiveKey={[1]}
            items={[
              {
                key: 1,
                label: (
                  <span style={{ zIndex: 11, position: 'absolute' }}>Статистика проверок за последние 30 дней</span>
                ),
                children:
                  <Tabs className={styles.graphsTabs} style={{ position: 'relative', bottom: '50px' }} defaultActiveKey="1" items={collapseGraphsTabs} />
              },
            ]} />

        </Col>
      </Row>
  )
}