import React, { useEffect, useState } from "react";
import { Space, Typography, message, Card, Alert, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Sandbox.module.css";
import { Row, Col } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import SandboxList from "./SandboxList";
import {
  getSandboxRequestsTableData,
  getSandboxMyAssets,
  // getSandboxStats,
  getSandboxOverallStatistics,
  getSandboxRequestDetails,
  getGraphsData,
} from "../../store/sandbox";
import {
  getSandboxFileErrors,
  getSandboxLoaders,
  // getSandboxNextPage,
  getSandboxOverallStats,
} from "../../store/sandbox/selectors";
import { IconSandboxSettings } from "../../icons/IconSandboxSettings";
import HashChecker from "./HashChecker";
//import FileChecker from "./FileChecker";
import GraphsArea from "./GraphsArea";
import MetricsArea from "./MetricsArea";
import DetailsDrawer from "./DetailsDrawer";
import { getOrganisation } from "../../store/auth/selectors";
const { Title } = Typography;

function SandboxArea({ permissions }) {
  const [, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  // const dataStats = useSelector(getSandboxStatsData);
  const isLoader = useSelector(getSandboxLoaders);
  message.config({ duration: 5 });
  const fileError = useSelector(getSandboxFileErrors);
  const [search, setSearch] = useState("");
  const [refreshing, setRefreshing] = useState(false)
  const [verdictFilter, setVerdictFilter] = useState(null);
  const [source, setSource] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [fileTypeFilter, setFileTypeFilter] = useState(null);
  // const [renew, setRenew] = useState(false);
  const [serial, setSerial] = useState(null);
  const [sourceIn, setSourceIn] = useState('00,01,02');
  const [currentPage, setCurrentPage] = useState(1);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false)
  const [point, setPoint] = useState(null);
  const [value, setValue] = useState(null);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [requestUid, setRequestUid] = useState(null)
  const sandboxOverallStatistics = useSelector(getSandboxOverallStats);
  const org = useSelector(getOrganisation);

  function formatDate(input) {
    return input.split('.')[0];
  }

  useEffect(() => {
    if (requestUid !== null) {
      dispatch(getSandboxRequestDetails(requestUid))
    }
  }, [requestUid])

  const fetchData = () => {
    let filters;
    if (statusFilter || verdictFilter || search || fileTypeFilter || value || dateAfterFilter || dateBeforeFilter || refreshing || currentPage) {
      filters = {
        status__in: statusFilter?.join(','),
        source__in: value==='API'? '01' : sourceIn,
        user_id__in: sourceIn === '00' || sourceIn === '01' ? null : (Array.isArray(value) ? value?.join(',') : value),
        firewall_id: sourceIn === '02' || sourceIn === '01' ? null : (Array.isArray(value) ? value?.join(',') : value),
        search: search,
        file_type__in: fileTypeFilter?.join(','),
        verdict__in: verdictFilter?.join(','),
        created_at__gte: dateAfterFilter ? formatDate(dateAfterFilter) : null,
        created_at__lte: dateBeforeFilter ? formatDate(dateBeforeFilter) : null,
        page: refreshing ? 1 : currentPage

      };
    }
    Object.keys(filters).forEach(key => {
      if (filters[key] === null || filters[key] === undefined || filters[key] == []) {
        delete filters[key]
      }
    })
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(
        getSandboxRequestsTableData({ filters })
      );
      setRefreshing(false)
    }
  };
  // useEffect(() => {
  //   (async () => {
  //     if (currentPage) {
  //       fetchData(true);
  //     }
  //   })();
  // }, [currentPage]);

  // useEffect(() => {
  //   if (isLoader) return;
  //   if (observer?.current) observer?.current.disconnect();
  //   let callback = function (
  //     entries
  //     //observer
  //   ) {
  //     if (entries[0].isIntersecting && nextPage && assetsData.length > 0) {
  //       setNeedToLoad(true);
  //     }
  //   };
  //   observer.current = new IntersectionObserver(callback);
  //   observer.current.observe(lastItem?.current);
  // }, [isLoader]);

  useEffect(() => {
    if (permissions?.full_access || permissions?.read_access) {
      const timerRefresh = setTimeout(fetchData, 600);
      return () => {
        clearTimeout(timerRefresh);
      };
    }
  }, [search, verdictFilter, fileTypeFilter, value, permissions, statusFilter, dateAfterFilter, dateBeforeFilter, currentPage]);


  useEffect(() => {
    let filters = {
      source__in: '00,01,02'
    }
    if (permissions?.full_access || permissions?.read_access) {
      dispatch(getSandboxMyAssets());
      dispatch(getSandboxOverallStatistics({ filters }));
      dispatch(getGraphsData({ filters }));
    }
  }, [permissions]);

  const clearFilters = () => {
    setStatusFilter(null)
    setSearch("");
    setFileTypeFilter(null);
    setVerdictFilter(null);
    setPoint(null);
    setValue(null);
    setSourceIn('00,01,02');
    setDateAfterFilter(null);
    setDateBeforeFilter(null);
  };

  const refresh = () => {
    if (permissions?.full_access || permissions?.read_access) {
      fetchData()
    }
  }

  return (
    <Space direction="vertical" size={20} style={{ width: "100%" }}>
      {contextHolder}
      <Row>
        <Col span={12}>
          <Title level={3} style={{ margin: 0 }}>
            SANDBOX
          </Title>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button className={styles.settingsBtn} icon={<IconSandboxSettings style={{ stroke: 'black' }} />} disabled>Настройки</Button>
        </Col>
      </Row>
      <DetailsDrawer openDetailsDrawer={openDetailsDrawer} setOpenDetailsDrawer={setOpenDetailsDrawer} requestUid={requestUid} />
      <HashChecker />
      {/* <FileChecker /> */}
      <GraphsArea />
      {
        (permissions?.full_access || permissions?.read_access) && (
          <div style={{ opacity: isLoader ? 0.3 : 1 }}>
            {fileError && (
              <Alert message={fileError} type="error" key={"error"} showIcon>
                {fileError}
              </Alert>
            )}
            <Card style={{ position: "relative" }}>
              <MetricsArea dataStats={sandboxOverallStatistics} />
              {isLoader && <PreloaderNew />}
            </Card>
            <SandboxList
              setSearch={setSearch}
              refresh={refresh}
              fetchData={fetchData}
              setRefreshing={setRefreshing}
              search={search}
              setVerdictFilter={setVerdictFilter}
              verdictFilter={verdictFilter}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              // setRenew={setRenew}
              fileTypeFilter={fileTypeFilter}
              setFileTypeFilter={setFileTypeFilter}
              serial={serial}
              setSerial={setSerial}
              source={source}
              setSource={setSource}
              setOpenDetailsDrawer={setOpenDetailsDrawer}
              setRequestUid={setRequestUid}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
              point={point}
              setPoint={setPoint}
              value={value}
              setValue={setValue}
              setSourceIn={setSourceIn}
              clearFilters={clearFilters}
              org={org}
              dateAfterFilter={dateAfterFilter}
              setDateAfterFilter={setDateAfterFilter}
              dateBeforeFilter={dateBeforeFilter}
              setDateBeforeFilter={setDateBeforeFilter}

            />
          </div>
        )
      }
      {/* <div ref={lastItem} style={{ height: 20 }} /> */}
      {
        permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />
      }
    </Space >
  );
}

export default SandboxArea;
