export const getSandboxData = ({ sandbox }) => sandbox.sandboxData;
export const getUrlCheckErrors = ({ sandbox }) => sandbox.errors;
// export const getSandboxStatsData = ({ sandbox }) => sandbox.sandboxStats;
export const getSandboxLoaders = ({ sandbox }) => sandbox.loaders.common;
export const getSandboxGraphLoader = ({ sandbox }) => sandbox.loaders.graphs;
export const getSandboxTableLoader = ({ sandbox }) => sandbox.loaders.table;
export const getSandboxDetailsLoader = ({ sandbox }) => sandbox.loaders.details;
export const getSandboxHashLoader = ({ sandbox }) => sandbox.loaders.hashChecker;
export const getSandboxMyAssetsDrop = ({ sandbox }) => sandbox.sandboxAssets;
export const getSandboxFileErrors = ({ sandbox }) => sandbox.fileError;
export const getSandboxNextPage = ({ sandbox }) => sandbox.nextPage;
export const getSandboxOverallStats = ({ sandbox }) => sandbox.sandboxOverallStatistics
export const getAllFilesGraphData = ({ sandbox }) => sandbox.allFilesGraphData
export const getSuspiciousFilesGraphData = ({ sandbox }) => sandbox.suspiciousFilesGraphData
export const getFileTypesGraphData = ({ sandbox }) => sandbox.fileTypesGraphData
export const getSandboxReqTableData = ({ sandbox }) => sandbox.sandboxRequestsTableData;
export const getReqDetails = ({ sandbox }) => sandbox.sandboxRequestDetails
export const getHashDetails = ({sandbox}) => sandbox.hashCheckDetails
export const getHashErrors = ({sandbox}) =>sandbox.hashCheckerErrors