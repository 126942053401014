import React, { useEffect, useState } from 'react'
import { Row, Col, Collapse, Input, Button, Typography, Table, message, Badge } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './Sandbox.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getSandboxHashDetailsData, getDocFileSandBox } from '../../store/sandbox';
import { getHashDetails, getSandboxHashLoader, getHashErrors, getSandboxFileErrors } from '../../store/sandbox/selectors';
import { resetHashCheckerData } from '../../store/sandbox';
import { IconCopy} from '../../icons';
import { IconErrorHash } from '../../icons/IconErrorHash';
import { IconFileDownload } from '../../icons/IconFileDownload';

import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
const { Title, Text } = Typography;

export default function HashChecker() {

  const dispatch = useDispatch();
  const isLoader = useSelector(getSandboxHashLoader)
  const fileErrors = useSelector(getSandboxFileErrors)
  const [hash, setHash] = useState(null);
  let detailsData = {} ;
  let tableData = {};
  const allHashDetailsData = useSelector(getHashDetails)
  const hashErrors = useSelector(getHashErrors)
  useEffect(()=>{
    if (!hash){
      dispatch(resetHashCheckerData());
    }
  }, [hash])
  if (allHashDetailsData) {
    const { uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name, file_url, source_ip, ...rest } = allHashDetailsData;
    detailsData = { uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name, file_url, source_ip };
    tableData = { ...rest }
  }
  const [messageApi, contextHolder] = message.useMessage();
  const copyBufferTextTable = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Скопировано!',
          style: {
            marginTop: '90vh',
            marginLeft: '85vw'
          },
          duration: 2
        });
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Ошибка',
          style: {
            marginTop: '20vh',
          },
          duration: 2
        });
      });
  };

  const parseValue = (key, value) => {
    if (key === 'first_seen' || key === 'last_seen') {
      const date = (value.split('T')[0]).split('-').reverse()
      const time = (value.split('T')[1]).split('Z')[0]
      return (
        <div>
          <Text style={{ color: '#4B4F55' }}>{`${date[0]}.${date[1]}.${date[2]} `}</Text>
          <Text style={{ color: '#7A7D86' }}>{time.split('.')[0]}</Text>
        </div>
      )
    }
    else if (key === 'sha256') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ height: '100%', width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap', color: '#4B4F55' }}>{`${value} `}</div>
          <Button
            size="small"
            type="text"
            icon={<IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} />}
            onClick={() => {
              copyBufferTextTable(value);
            }}
          />
        </div>
      )
    }
    else if (key === 'verdict') {
      return (viewVerdictBadge(value, 'parus'))
    }
    else if (key === 'file_name') {
      return (
        <div style={{ height: '100%', width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap', color: '#4B4F55' }}>
          <Text style={{ color: '#4B4F55' }}>{`${value} `}</Text>
        </div>
      )
    }
    else if (key === 'file_url' || key === 'source_ip') {
      return (

        <div style={{ display: 'flex', alignItems: 'center', height: '100%', width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap' }}>
          <Text style={{ color: '#4B4F55' }}>{`${value} `}</Text>
          <Button
            size="small"
            type="text"
            icon={<IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} />}
            onClick={() => {
              copyBufferTextTable(value);
            }}
          />
        </div>


      )
    }
    else if (key === 'file_size') {
      return (
        <Text style={{ color: '#4B4F55' }}>{`${value} Mb`}</Text>
      )
    }
    else {
      return (
        <Text style={{ color: '#4B4F55' }}>{value}</Text>
      )
    }
  }

  const changeKeyToTitle = (key) => {
    switch (key) {
      case 'sha256':
        return 'sha 256';
      case 'verdict':
        return 'Parus verdict';
      case 'first_seen':
        return 'First seen (UTC)';
      case 'last_seen':
        return 'Last seen (UTC)';
      case 'hits':
        return 'Hits';
      case 'file_size':
        return 'File size';
      case 'file_type':
        return 'File type';
      case 'file_name':
        return 'File name';
      case 'file_url':
        return 'Source URL';
      case 'source_ip':
        return 'Source IP';
      default:
        break;
    }

  }


  const viewVerdictBadge = (verdict, parus) => {
    switch (verdict) {
      case "Malware":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#EB5757',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
        )
      case "Clean":
          return (
            <Badge style={{
              borderRadius: '2px',
              color: '#219653',
              fontSize: 12
            }} count={parus==='parus'? 'benign'.toUpperCase() : verdict?.toUpperCase()} showZero color='#E0F6EA' />
          )

      case "Grayware":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#E39800',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#FFF9EC' />
        )
      case "Not applicable":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#7a2300',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#ff7a45' />
        )
      case "Adware and other":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#cc7810',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
        )
      case "Not categorized":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: 'grey',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
        )
      case null:
        return (
          <Badge style={{
            borderRadius: '2px',
            color: 'grey',
            fontSize: 12
          }} count={'No data'.toUpperCase()} showZero color='#f8f4ff' />
        )
      case "Unknown":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: 'grey',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
        )
      case "NotAVirus":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#cc7810',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
        )
      case "Benign":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#219653',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#E0F6EA' />
        )
      case "Unsupported":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#cc7810',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
        )
      case "Error":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#12020c',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#9e1068' />
        )
      case "Low Risk":
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#cc7810',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#ffda99' />
        )
      default:
        return (
          <Badge style={{
            borderRadius: '2px',
            color: '#EB5757',
            fontSize: 12
          }} count={verdict?.toUpperCase()} showZero color='#FFECEC' />
        )
    }
  };
  const tableDataset = [
    {
      source: 'Palo Alto Networks',
      verdict: tableData?.verdict_palo_alto,
      finished_at: tableData?.finished_palo_alto_at,
      details: tableData?.details_palo_alto,
      report: tableData?.report_palo_alto

    },
    {
      source: 'Fortinet',
      verdict: tableData?.verdict_fortisandbox,
      finished_at: tableData?.finished_fortisandbox_at,
      details: tableData?.details_fortisandbox,
      report: tableData?.report_fortisandbox

    },
    {
      source: 'Kaspersky',
      verdict: tableData?.verdict_kaspersky,
      finished_at: tableData?.finished_kaspersky_at,
      details: tableData?.details_kaspersky,
      report: tableData?.report_kaspersky
    }
  ]
  const checkTableColumns = [
    createColumn('Источник', 'source', '30%', (text) => { return (<Text >{text}</Text>) }),
    createColumn('Вердикт', 'verdict', '15%', (text) => {
      if (text != null) {
        return (viewVerdictBadge(text))
      }
    }),
    createColumn('Дата вердикта', 'finished_at', '25%', (text) => { if (text != null) return (<Text >{parseValue('last_seen', text)}</Text>) }),
    createColumn('Детали', 'details', '20%', (text) => { return (text?
      text?.split("\n")?.map((item) => (
          <div>{item}</div>))
: " - ")}),
    createColumn('Отчет', 'report', '15%', (text, row) => { if (text) return (<IconFileDownload onClick={()=>dispatch(getDocFileSandBox({hash: detailsData?.sha256, source: row?.source==='Palo Alto Networks'? 'palo-alto': 'forti'}))}/>) }),
  ]
  function createColumn(title, key, width, render) {
    return { title, key, dataIndex: key, width, render };
  }

  const detailItemStyle = { padding: '8px 0px 8px 0px', fontWeight: 400 }
  return (
    <Row justify={"center"} gutter={8}>
      <Col span={24}>
        <Collapse
          size="small"
          expandIcon={({ isActive }) => <DownOutlined style={{ fontSize: 14, paddingTop: 8 }} rotate={isActive ? 180 : 0} />}
          style={{
            backgroundColor: 'white',
            padding: '16px 24px 16px 24px',
            alignContent: 'center',
            borderRadius: '8px',
            fontSize: 18,
            fontWeight: 600,
            minHeight: '60px',
          }}
          ghost
          defaultActiveKey={[1]}
          items={[
            {
              key: 1,
              label: (
                <span>Проверить хеш</span>
              ),
              children:
                <div style={{ opacity: isLoader ? 0.2 : 1 }}>
                  {contextHolder}
                  <Row span={24}>
                    <Col span={20}>
                      <Input style={{
                        width: '99%',
                        height: '44px',
                        borderRadius: '4px',

                      }} allowClear={true} onChange={(e) => setHash(e.target.value)} onClear={() =>{ setHash(null)}} placeholder="Введите хеш"></Input>
                    </Col>
                    <Col span={4}>
                      <Button onClick={() => {
                        if (hash) {
                          dispatch(getSandboxHashDetailsData({ hash }))
                        }
                      }} className={styles.sandboxBtn}>Проверить</Button>
                    </Col>
                  </Row>
                  {isLoader && <PreloaderNew />}
                  {detailsData?.sha256 && <div>
                    <Row>
                      <Title style={{ color: '#333333', margin: '14px 0px 14px 0px' }} level={5}>Информация по файлу</Title>
                    </Row>
                    <Row >
                      <Col span={12} >
                        {Object.keys(detailsData).map(key => {
                          if (key !== 'uid' && key !== 'file_name' && key !== 'file_url' && key !== 'source_ip') {
                            return (
                              detailsData[key] !== null && (
                                <Row style={detailItemStyle} key={key}>
                                  <Col span={9} style={{ color: '#7A7D86', paddingLeft: '24px' }}>
                                    {changeKeyToTitle(key)}
                                  </Col>
                                  <Col span={15} style={{ display: 'flex', alignItems: 'center', color: '#4B4F55' }}>
                                    {parseValue(key, detailsData[key])}
                                  </Col>
                                </Row>)
                            )
                          }
                        })}
                      </Col>
                      <Col span={12}>
                        {Object.keys(detailsData).map(key => {
                          if (key === 'file_name' || key === 'file_url' || key === 'source_ip') {
                            return (
                              detailsData[key] !== null && (
                                <Row style={detailItemStyle} key={key}>
                                  <Col span={9} style={{ color: '#7A7D86', paddingLeft: '24px' }}>
                                    {changeKeyToTitle(key)}
                                  </Col>
                                  <Col span={15} style={{ display: 'flex', alignItems: 'center', color: '#4B4F55' }}>
                                    {parseValue(key, detailsData[key])}
                                  </Col>
                                </Row>)
                            )
                          }
                        })}
                      </Col>
                    </Row>
                    <Row>
                      <Title style={{ color: '#333333', margin: '14px 0px 14px 0px' }} level={5}>Таблица проверок</Title>
                    </Row>
                    <Table
                      style={{
                        fontWeight: 400
                      }}
                      className={styles.table}
                      columns={checkTableColumns}
                      dataSource={tableDataset}
                      pagination={false}
                      rowKey={(obj) => obj?.source + 'detailsHash'}
                    /></div>}
                    {hashErrors && <div style={{display:'flex', alignItems:'center', gap:8, marginTop:12}}>
                      <IconErrorHash/><Text style={{fontWeight:400, color: '#EB5757'}}>{hashErrors}</Text>
                      </div>}
                    {fileErrors && <div style={{display:'flex', alignItems:'center', gap:8, marginTop:12}}>
                      <IconErrorHash/><Text style={{fontWeight:400, color: '#EB5757'}}>{fileErrors}</Text>
                      </div>}

                </div>

            },
          ]} />
      </Col>
    </Row>
  )
}