import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Select, Space, Radio, Divider, List, DatePicker, Popover } from 'antd';
import { CalendarOutlined,CloseOutlined} from '@ant-design/icons';
import { IconSearch } from '../../icons';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
// const options = [
//     { label: 'За сегодня', value: 'today' },
//     { label: 'За последние 24 часа', value: 'last24Hours' },
//     { label: 'За последние 7 дней', value: 'last7days' },
//     { label: 'За текущий месяц', value: 'thisMonth' },
//     { label: 'За последние 3 месяца', value: 'last3Months' },
//     { label: 'За год', value: 'lastYear' },
//     { label: 'Укзать период', value: 'setPeriod' }
// ];
export default function TableFiltersArea({
    setSearch,
    search,
    assetsData,
    refresh,
    // fetchData,
    setRefreshing,
    // setSerial,
    myAssetsDrop,
    verdictFilter,
    setVerdictFilter,
    setCurrentPage,
    fileTypeFilter,
    setFileTypeFilter,
    clearFilters,
    statusFilter,
    setStatusFilter,
    point,
    setPoint,
    value,
    setValue,
    setSourceIn,
    org,
    dateAfterFilter,
    setDateAfterFilter,
    setDateBeforeFilter,
    dateBeforeFilter,
}) {
    // const [selectedOption, setSelectedOption] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);

    const [viewDataFilter, setViewDataFilter] = useState('Выберите интервал');
    const [openDate, setOpenDate] = useState(false);

    const handleOpenChangeDate = () => setOpenDate(!openDate);
    const frequentlyUsedFilters = [];
    const dateFormat = "YYYY-MM-DD hh:mm:ss";

    useEffect(() => {
        if (dateBeforeFilter === null && dateAfterFilter === null) {
            setViewDataFilter('Выберите интервал')
        }
    }, [dateBeforeFilter, dateAfterFilter])

    
    const resetDateFilter = (value) => {
        const date =
            value.replaceAll('T', ' ')
        return dayjs(date, dateFormat);
    };
    

    const changeDateToUTCFormatString = (date) =>{
        return date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[0].split('.').reverse().join('-') + 'T' + date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[1]
    }

    const handleChangeDateAfter = (e) => {
        if (e?.$d) {
            setDateAfterFilter(changeDateToUTCFormatString(e))
            if (dateBeforeFilter) {
                setViewDataFilter(
                    e?.$d.toLocaleString("ru-RU").replace(",", "") +
                    " - " +
                    dateBeforeFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + dateBeforeFilter.toLocaleString('ru-RU').split('T')[1]
                );
            }
            else
                setViewDataFilter(
                    e?.$d.toLocaleString("ru-RU").replace(",", "") + " - "
                );
        } else setDateAfterFilter(null);
    };
    const handleChangeDateBefore = (e) => {
        if (e?.$d) {
            setDateBeforeFilter(changeDateToUTCFormatString(e))
            if (dateAfterFilter) {
                setViewDataFilter(
                    dateAfterFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + dateAfterFilter.toLocaleString('ru-RU').split('T')[1] +
                    " - " +
                    e?.$d.toLocaleString("ru-RU").replace(",", "")
                );
            } else
                setViewDataFilter(
                    " - " + e?.$d.toLocaleString("ru-RU").replace(",", "")
                );
        } else setDateBeforeFilter(null);
    };
    const calculateMonth = () => {
        let date = new Date();
        date.setDate(date.getDate() - 30);
        return date;
    };
    const content = (
        <List
            size="small"
            header={false}
            footer={
                <div>
                    <hr />
                    <Space direction="vertical">
                        <span>Начало (UTC)</span>
                        <DatePicker
                            renderExtraFooter={() => null}
                            style={{width: '200px'}}
                            footer={null}
                            format="DD-MM-YYYY hh:mm:ss"
                            onChange={(e) => {
                                setCurrentPage(1);
                                handleChangeDateAfter(e);
                            }}
                            showNow={false}
                            showTime
                            showToday={false}
                            placeholder="Выберите дату начала"
                            disabledDate={(d) => {
                                !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                            }}
                            value={
                                dateAfterFilter ? resetDateFilter(dateAfterFilter) : null
                            }
                        />
                        <span>Окончание (UTC)</span>
                        <DatePicker
                            showNow={false}
                            style={{width: '200px'}}
                            showToday={false}
                            renderExtraFooter={() => null}
                            showTime
                            format="DD-MM-YYYY HH:mm:ss"
                            disabledDate={(d) => {
                                !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                            }}
                            onChange={(e) => {
                                setCurrentPage(1);
                                handleChangeDateBefore(e);
                            }}
                            placeholder="Выберите дату окончания"
                            value={
                                dateBeforeFilter
                                    ? resetDateFilter(dateBeforeFilter)
                                    : null
                            }
                        />
                    </Space>
                </div>
            }
            dataSource={frequentlyUsedFilters}
            renderItem={(item) => (
                <List.Item
                    style={{
                        cursor: "pointer",
                        border: "none",
                    }}
                >
                    {item}
                </List.Item>
            )}
        />
    );


    const selectDropdown = (menu, setPoint, point, setValue) => {
        return (
            <>
                <Radio.Group
                    onChange={(e) => {
                        if (e.target.value === 'api') {
                            setPoint(e.target.value)
                            setSourceIn('01')
                            setValue('API')
                        }
                        else {
                            setPoint(e.target.value);
                            setValue(null)
                        }

                    }}
                    value={point}
                >
                    <div
                        style={{
                            padding: "0 10px",
                            fontSize: 20,
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,

                        }}
                    >
                        <Radio style={{ fontWeight: '400' }} value={'firewall'}>Устройство</Radio>
                        <Radio style={{ fontWeight: '400' }} value={'customer'}>Пользователь</Radio>
                        <Radio style={{ fontWeight: '400' }} value={'api'}>API</Radio>

                    </div>
                </Radio.Group>
                {point !== 'api' && <Divider style={{ margin: "8px 0" }} />}
                {(point === 'customer' || point === 'firewall') && menu}
            </>
        );
    };
    const getSelectArray = (key) => {
        if (key === 'firewall') {setSourceIn('00'); return myAssetsDrop }
        if (key === 'api') {setSourceIn('01') }
        if (key === 'customer') {setSourceIn('02'); return org.users }
    }








    const frequency = {

        lastHour: {
            numberDate: new Date() - 3600000,
            title: "За последний час",
        },
        last24Hours: {
            numberDate: new Date() - 3600000 * 24,
            title: "За последние 24 часа",
        },
        last7days: {
            numberDate: new Date() - 3600000 * 24 * 7,
            title: "За последние 7 дней",
        },
        thisMonth: {
            numberDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            title: "За текущий месяц",
        },
        last3Months: {
            numberDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
            title: "За последние 3 месяца",
        },
        lastYear: {
            numberDate: new Date(new Date().setYear(new Date().getFullYear() - 1)),
            title: "За год",
        },

    };






    const handleSetDateFilters = (value) => {
        if (frequency[value]) {
            setDateBeforeFilter(null)
            //   setDateFrequentlyFilter(value);
            setViewDataFilter(frequency[value].title);
            const actualDate = new Date(frequency[value].numberDate);
            setDateAfterFilter(actualDate.toISOString());
        }

        setOpenDate(false);
    };

    Object.keys(frequency).forEach((key, i) => {
        frequentlyUsedFilters.push(
            <Button
                style={{ background: "transparent", border: "none" }}
                key={i}
                onClick={() => {
                    handleSetDateFilters(key);
                }}
            >
                {frequency[key].title}
            </Button>
        );
    });
    return (
        <>
            <Row gutter={16} style={{ marginBottom: '16px' }}>

                <Col span={12}>

                    <Input
                        prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
                        value={search}
                        suffix={search && <CloseOutlined style={{color:'red', cursor:'pointer'}} onClick={()=>setSearch('')} />}
                        style={{ width: '100%', height: '44px', borderRadius: '4px' }}
                        disabled={assetsData ? false : true}
                        placeholder="Поиск по hash или названию файла"
                        onChange={(e) => {
                            setCurrentPage(1);
                            setSearch(e.target.value);
                        }}
                    />
                </Col>
                <Col span={10}>

                    <Space.Compact
                        title="Выберите интервал"
                        style={{ width: '100%' }}
                    >
                        <Popover
                            placement="bottomLeft"
                            open={openDate}
                            onOpenChange={handleOpenChangeDate}
                            title={false}
                            content={content}
                            trigger="click"
                        >
                            <Button
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: '100%',
                                    textAlign: 'center', 
                                    height: '44px',
                                    color: viewDataFilter !== 'Выберите интервал' ? 'black' : '#C1C2C9'
                                }}
                            >
                                <CalendarOutlined
                                    style={{

                                        padding: "5px 0",
                                        color: "#6679A9",
                                    }}
                                />
                              <span style={{fontSize: 14, padding: "0 5px", marginTop: 2 }}>
                                    {viewDataFilter}
                                </span>
                            </Button>
                        </Popover>
                    </Space.Compact>
                </Col>
                <Col span={2}>

                    <Button
                        style={{ width: '100%', height: '100%',borderRadius: '4px', color:'white', backgroundColor:'#2E496F'}}
                        // icon={<ReloadOutlined style={{color:'white'}}/>}
                        onClick={()=>{
                            setRefreshing(true)
                            refresh()
                        }}
                    >
                        Обновить
                    </Button>

                </Col>
            </Row>

            <Row gutter={16} style={{height:44}}>
                <Col span={6}>
                        <Select
                            showSearch
                            maxTagCount={1}
                            mode={point === 'firewall' ? 'single' : "multiple"}
                            allowClear={true}
                            placeholder="Отправитель"
                            //maxTagCount="responsive"
                            style={{ width: "100%", textAlign: "left", height: '100%' }}
                            dropdownRender={(menu) =>
                                selectDropdown(menu, setPoint, point, setValue)
                            }
                            value={value || undefined}
                            onChange={(e) => {setCurrentPage(1); setValue(e) }}
                            onClear={()=>{setPoint(null), setValue(null), setCurrentPage(1)}}
                            options={
                                getSelectArray(point)?.map((a) => ({
                                    value: a.id ? a.id : a.firewall_id,
                                    label: a.firewall_id ? a.firewall_id : a.first_name + ' ' + a.last_name,
                                }))
                            }
                        />

                </Col>
                <Col span={6}>
                    <Select
                        maxTagCount={2}
                        data-testid='status-selector'
                        style={{ width: "100%", height: '100%', marginRight: '12px' }}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setStatusFilter(e)
                        }}
                        //allowClear={true}
                        mode='multiple'
                        placeholder="Статус"
                        value={statusFilter || undefined}
                        options={[
                            { value: "accepted,pending,stage_01,stage_02,stage_03", label: "ПРОВЕРЯЕТСЯ" },
                            { value: "finished,succeeded", label: "ПРОВЕРЕН" },
                            { value: "error,timeout,failed", label: "ОШИБКА" },
                        ]}
                    />
                </Col>
                <Col span={5}>
                    <Select
                        data-testid='verdict-selector'
                        maxTagCount={2}
                        style={{ width: "100%", height: '100%', borderRadius: '4px', marginRight: '12px' }}
                        mode="multiple"
                        value={verdictFilter || undefined}
                        allowClear={true}
                        onChange={(e) => {
                            setCurrentPage(1);
                            setVerdictFilter(e);
                        }}
                        placeholder="Вердикт"
                        //defaultValue={''}
                        options={[
                            //{ value: '', label: "Выберите вердикт" },
                            { value: "Clean", label: "Benign" },
                            { value: "Malware", label: "Malware" },
                            { value: "Grayware", label: "Grayware" },
                            { value: 'Unknown', label: 'Unknown' }
                        ]}
                    />
                </Col>
                <Col span={5}>
                    <Select
                        data-testid='type-selector'
                        style={{ width: "100%", height: '100%', borderRadius: '4px', marginRight: '12px' }}
                        mode="multiple"
                        onChange={(e) => {
                            setCurrentPage(1)
                            setFileTypeFilter(e);
                        }}
                        placeholder="Тип файла"
                        allowClear={true}
                        maxTagCount={2}
                        value={fileTypeFilter || undefined}
                        options={[
                            { value: "pdf", label: "pdf" },
                            { value: "ms-office", label: "ms-office" },
                            { value: "pe", label: "pe" },
                            { value: "jar", label: "jar" },
                            { value: "flash", label: "flash" },
                            { value: "archive", label: "archive" },
                            { value: "script", label: "script" },
                            { value: "apk", label: "apk" },
                            { value: "linux", label: "linux" },
                        ]}
                    />
                </Col>
                <Col span={2}>

                    <Button
                        style={{
                            backgroundColor: (verdictFilter || search || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter) ? '#2E496F' : "transparent",
                            color: (verdictFilter || search || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter) ? 'white' : '#2E496F',
                            borderRadius: '4px',
                            height: '100%',
                            width: '100%'
                        }}
                        disabled={!(verdictFilter || search || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter)}
                        onClick={() => clearFilters()}
                    >
                        Сбросить
                    </Button>

                </Col>
            </Row>
        </>
    )
}