import React from "react";
import {
  Button,
  Space,
  Table,
  Badge,
  message,
  Col,
  Row,
  Typography
} from "antd";
import { useSelector } from "react-redux";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconCopy } from "../../icons";

import {
  // getSandboxData,
  getSandboxReqTableData,
  getSandboxMyAssetsDrop,
  getSandboxTableLoader
} from "../../store/sandbox/selectors";
import styles from './Sandbox.module.css'
// import { IconPAN } from "../../icons/IconPAN";
// import { IconForti } from "../../icons/IconForti";
import TableFiltersArea from "./TableFiltersArea";
import { EyeOutlined } from "@ant-design/icons";
function SandboxList({
  setSearch,
  search,
  fetchData,
  refresh,
  setRefreshing,
  // renew,
  // setRenew,
  statusFilter,
  setStatusFilter,
  verdictFilter,
  setVerdictFilter,
  fileTypeFilter,
  setFileTypeFilter,
  currentPage,
  setCurrentPage,
  serial,
  setSerial,
  source,
  setSource,
  setOpenDetailsDrawer,
  point,
  setPoint,
  value,
  setValue,
  org,
  setSourceIn,
  clearFilters,
  dateAfterFilter,
  setDateAfterFilter,
  setDateBeforeFilter,
  dateBeforeFilter,
  setRequestUid,
}) {
  // const [messageApi] = message.useMessage();
  // const dispatch = useDispatch();
  const assetsData = useSelector(getSandboxReqTableData);
  const isLoader = useSelector(getSandboxTableLoader)
  // const [copyText, setCopyText] = useState(null);
  const myAssetsDrop = useSelector(getSandboxMyAssetsDrop);
  // const sandboxOverallStatistics = useSelector(getSandboxOverallStatistics);
  // const shouldDisplayButton = true;
  const { Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage()
  const copyBufferTextTable = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Скопировано!',
          style: {
            marginTop: '90vh',
            marginLeft: '85vw'
          },
          duration: 2
        });
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Ошибка',
          style: {
            marginTop: '20vh',
          },
          duration: 2
        });
      });
  };


  function renderMultilineTitle(title) {
    return (
      <Space size={0} direction="vertical">
        <span>{title}</span>
      </Space>
    );
  }

  const viewStatus = (status) => {
    let color;
    if (status === 'accepted' || status === 'pending' || status === 'stage_02' || status === 'stage_01' || status === 'stage_03 ') {
      color = "#FFF9EC";
    }
    else if (status === 'finished' || status === 'succeeded') {
      color = '#EAF0FF';
    }
    else if (status === 'error' || status === 'timeout' || status === 'failed') {
      color = "#FFECEC";
    }
    else {
      color = "grey";
    }
    return color;
  };

  const viewStatusTextColor = (status) => {
    let color;
    let text;
    if (status === 'accepted' || status === 'pending' || status === 'stage_02' || status === 'stage_01' || status === 'stage_03 ') {
      color = "#E39800";
      text = 'проверяется';
    }
    else if (status === 'finished' || status === 'succeeded') {
      color = '#327FEF';
      text = 'проверен';
    }
    else if (status === 'error' || status === 'timeout' || status === 'failed') {
      color = "#EB5757";
      text = 'ошибка';
    }
    else {
      color = "grey";
      text = ''
    }
    return { color: color, text: text };
  };

  const viewVerdict = (verdict) => {
    let color;
    switch (verdict) {
      case "Malware":
        color = "#FFECEC";
        break;
      case "Clean":
        color = "#E0F6EA";
        break;
      case "Grayware":
        color = '#FFF9EC';
        break;
      default:
        color = "#f8f8ff";
    }
    return color;
  };

  const viewVerdictTextColor = (verdict) => {
    let color;
    switch (verdict) {
      case "Malware":
        color = "#EB5757";
        break;
      case "Clean":
        color = "#219653";
        break;
      case "Grayware":
        color = '#E39800';
        break;
      default:
        color = "grey";
    }
    return color;
  };

  const changeDate = (date) => {
    if (date) {
      const formatDate = date?.split("T");
      const reverseDate =
        formatDate[0].split("-").reverse().join(".") +
        " " +
        formatDate[1].split(".")[0];
      return reverseDate;
    } else return null;
  };
  const columns = [
    createColumn(
      renderMultilineTitle(<div>Начало проверки (UTC)</div>, "Ordered Desc"),
      "created_at", '10%',
      (text) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>{changeDate(text).split(' ')[0]}</Text>
            <Text style={{ color: '#7A7D86' }}>{changeDate(text).split(' ')[1]}</Text>
          </div>
        )
      }
    ),
    createColumn(<div>Конец проверки (UTC)</div>, "finished_check_at", '10%', (text) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>{changeDate(text)?.split(' ')[0]}</Text>
          <Text style={{ color: '#7A7D86' }}>{changeDate(text)?.split(' ')[1]}</Text>
        </div>
      )
    }),
      createColumn(<div>Отправитель</div>, "source_id", '10%'),
      createColumn(<div>Статус</div>, "status", '10%', (text) => (
        <Badge style={{
          display: 'flex',
          alignItems: 'center',
          height: '26px',
          borderRadius: '2px',
          color: viewStatusTextColor(text).color
        }} count={viewStatusTextColor(text).text?.toUpperCase()} showZero color={viewStatus(text)} />
      )),
      // createColumn("Platform", "platform_name", (text) => text || "Нет данных"),
      createColumn(<div>sha256</div>, "checked_file_sha256", '8%', (text) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          ...{text?.slice(-10)}
          <Button
            size="small"
            type="text"
            icon={<IconCopy style={{ color: "#8090B8", width: 16, height: 16 }} />}
            onClick={() => {
              copyBufferTextTable(text);
            }}
          />
        </div>
      )),
      createColumn(<div>Вердикт</div>, "verdict", '7%', (text) => (
        <Badge style={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: '2px',
          height: '26px',
          color: viewVerdictTextColor(text)
        }} count={text !== 'Clean' ? text?.toUpperCase() : 'Benign'.toUpperCase()} showZero color={viewVerdict(text)} />
      )),
      createColumn(<div>Имя файла</div>, "file_name", '30%', (text) => {
        return (
          <div style={{ display: "flex", gap: 5, width: '90%', overflowWrap: 'break-word', wordBreak: 'break-word', flexWrap: 'nowrap' }}>
            {text}
          </div>)
      }),
      createColumn(<div>Тип</div>, "file_type", '3%'),
      createColumn(<div>Детали</div>, "uid", '7%', (uid) => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <EyeOutlined style={{ fontSize: '17px', color: '#8090B8', cursor: 'pointer', paddingTop: 3 }} onClick={() => { setOpenDetailsDrawer(true); setRequestUid(uid) }} />
      </div>)),
  ];

  const handlePageChange = (pagination) => {
    setCurrentPage(pagination.current)
  }
  return (
    <Space
      direction="vertical"
      
      size={20}
      style={{ width: "100%", position: "relative", marginTop: "10px" }}>
        {contextHolder}
      <TableFiltersArea
        search={search}
        refresh={refresh}
        setRefreshing = {setRefreshing}
        fetchData = {fetchData}
        setSearch={setSearch}
        assetsData={assetsData}
        serial={serial}
        setSerial={setSerial}
        myAssetsDrop={myAssetsDrop}
        setSourceIn={setSourceIn}
        setSource={setSource}
        source={source}
        setFileTypeFilter={setFileTypeFilter}
        fileTypeFilter={fileTypeFilter}
        point={point}
        setPoint={setPoint}
        value={value}
        setValue={setValue}
        // shouldDisplayButton={shouldDisplayButton}
        clearFilters={clearFilters}
        setCurrentPage={setCurrentPage}
        verdictFilter={verdictFilter}
        setVerdictFilter={setVerdictFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        org={org}
        setDateBeforeFilter={setDateBeforeFilter}
        dateBeforeFilter={dateBeforeFilter}
        dateAfterFilter={dateAfterFilter}
        setDateAfterFilter={setDateAfterFilter}
      />

      {assetsData && (
        <>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}><Text style={{ fontSize: '13px', color: '#8090B8', lineHeight: 0, marginRight: 8 }}>Всего</Text><Text style={{ fontSize: '13px', lineHeight: 0, }}>{assetsData?.total}</Text></div>

          <Row gutter={[8, 8]} justify="center">
            <Col span={24} style={{ background: "white", borderRadius: "8px", padding: 0 }}>
              <Table
                loading={{ indicator: <PreloaderNew />, spinning: isLoader }}
                columns={columns}
                className={styles.table}
                rowKey={(obj) => obj.uid}
                size="small"
                pagination={{
                  current: currentPage,
                  onChange: handlePageChange,
                  total: assetsData?.total,
                  defaultPageSize: 50,
                  showSizeChanger: false
                }}
                onChange={handlePageChange}
                style={{ overflowX: "auto" }}
                dataSource={assetsData?.items}
              />
            </Col>
          </Row>
        </>
      )}

    </Space>
  );
}

function createColumn(title, key, width, render) {
  return { title, key, dataIndex: key, width, render };
}

export default SandboxList;
