import React from 'react'
import { Typography, Row } from 'antd';
import styles from './Sandbox.module.css'
const { Text } = Typography;
const styleTextGray = { color: "#7A7D86" };
const listItemStyle = { display: 'flex', justifyContent: 'space-between' }
export default function MetricsArea({ dataStats }) {
  return (
    <div data-testid='cards-area' className={styles.limitsCards}>
      <div>
        <Text>Проверено файлов</Text>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{dataStats?.checked_files_today}</Text>
        </Row>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Всего</Text>
          <Text >{dataStats?.checked_files_total}</Text>
        </Row>
      </div>
      <div>
        <Text>Проверено архивов</Text>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{dataStats?.checked_archives_today}</Text>
        </Row>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Всего</Text>
          <Text >{dataStats?.checked_archives_total}</Text>
        </Row>
      </div>
      <div>
        <div
          style={{
            backgroundColor: "#E0F6EA",

            color: "#219653",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          BENIGN
        </div>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{dataStats?.benign_requests_today}</Text>
        </Row>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Всего</Text>
          <Text >{dataStats?.benign_requests_total}</Text>
        </Row>

      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FFF9EC",

            color: "#E39800",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          GRAYWARE
        </div>

        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{dataStats?.grayware_requests_today}</Text>
        </Row>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Всего</Text>
          <Text >{dataStats?.grayware_requests_total}</Text>
        </Row>

      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FFECEC",
            color: "#EB5757",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          MALWARE
        </div>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{dataStats?.malware_requests_today}</Text>
        </Row>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Всего</Text>
          <Text >{dataStats?.malware_requests_total}</Text>
        </Row>
      </div>
    </div>

  )
}